<template>
    <!-- <div class="slider_close" @click="hideMask"><img src="@/assets/imgs/shut.png" alt=""></div>
    <div class="title">新建 <img src="@/assets/imgs/close.png" alt="" @click="hideMask"></div> -->
    <div class="content">
        <div>
            <h4 v-if="!flag" class="global_head_title">添加/编辑发票</h4>
            <div class="content_row flex">
                <label class="info" for="">发票编号<span class="redS">*</span></label>
                <div class="content_right">
                    <a-input v-model="sn" size="large" placeholder="请输入发票编号"/>
                </div>
            </div>
			<!-- <div class="content_row flex">
                <label class="info" for="">选择客户<span class="redS">*</span></label>
                <div class="content_right">
                    <a-select size="large" v-model="name" placeholder="请选择要添加的客户">
                        <a-select-option v-for="(item,index) in clientList" :key="index" :value="item.tmjcpb_corp__ids">
                            {{ item.tmjcpb_corp__name }}
                        </a-select-option>
                    </a-select>
                </div>
            </div> -->
            <div class="content_row flex">
                <label class="info" for="">选择客户 <span class="redS">*</span></label>
                <div class="content_right">
                    <a-select size="large" ref="sel" v-model="client2" placeholder="请选择要添加的客户" :open="false" @focus="chooseClient">
                        <!-- <a-select-option v-for="(item,index) in clientList" :key="index" :value="item.tmjcpb_corp__ids">
                            {{ item.tmjcpb_corp__name }}
                        </a-select-option> -->
                    </a-select>
                </div>
            </div>
            <client :clientFlag="clientFlag" :clientMode="clientMode" @updateClientFlag="updateClientFlag"></client>
            <div class="content_row flex">
                <label class="info" for="">选择订单</label>
                <div class="content_right">
                    <a-select size="large" v-model="contract" placeholder="请选择订单">
                        <a-select-option v-for="(item,index) in contractList" :key="index" :value="item.oid">
                            {{ item.osn }}
                        </a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">发票性质<span class="redS">*</span></label>
                <div class="content_right">
                    <a-radio-group name="radioGroup" v-model="type">
                        <a-radio :value="1">
                            普票
                        </a-radio>
                        <a-radio :value="2">
                            专票
                        </a-radio>
                        <a-radio :value="3">
                            收据
                        </a-radio>
                    </a-radio-group>
                </div>
            </div>
            
			<div class="content_row flex">
                <label class="info" for="">开票金额<span class="redS">*</span></label>
                <div class="content_right">
                    <a-input v-model="amount" size="large" type="number" placeholder="请输入金额" prefix="￥"/>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">开票日期<span class="redS">*</span></label>
                <div class="content_right">
                    <a-date-picker v-model="sign_time" :inputReadOnly="true" size="large"/>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">领取人</label>
                <div class="content_right">
                    <a-select size="large" v-model="staff_uid2" placeholder="请选择领取人" ref="sel2" :open="false" @focus="showAssign">
                        <!-- <a-select-option v-for="(item,index) in staffList" :key="index" :value="item.userid">
                            {{ item.name }}
                        </a-select-option> -->
                    </a-select>
                </div>
            </div>
            <staff :staffFlag="staffFlag"  @updateStaffFlag="updateStaffFlag"></staff>
            <div class="content_row flex">
                <label class="info" for="">领取日期</label>
                <div class="content_right">
                    <a-date-picker v-model="creater_time"  :inputReadOnly="true" size="large"/>
                </div>
            </div>
            <div class="content_row flex" >
                <label class="info" for="">备注</label>
                <div class="content_right">
                    <a-textarea v-model="doc" placeholder="请输入备注" :auto-size="{ minRows: 5, maxRows: 8 }"/>
                </div>
            </div>
            
            <div class="save"><a-button type="primary" @click="submit">保存</a-button></div>
        </div>
    </div>
        
</template>
<script>
// import $ from "jquery";
import {requestXml,ajaxUrl,getDateTime,isMobile} from '../../../assets/js/request';
import Client from '../../../components/client.vue';
import Staff from '../../../components/staff.vue';
var moment = require('moment');
export default {
    components: {
		Client,
        Staff
	},
    props: {
        orderId: {
            type: String
        }
    },
    data() {
        return {
            flag: true,
            clientMode: 0,
            url: ajaxUrl,
            // 发票编号
            sn: "",
            // 客户
            clientList: [],
            client: "",
            client2: "",
            clientFlag: false,

            // 订单列表
            contractList: [],
            contract: null,
            // 类型
            type: 1,
            // 开票日期
            sign_time: "",
            // 金额
            amount: null,
            // 领取人（员工）
            // staffList: [],
            staff_uid: "",
            staff_uid2: "",

            staffFlag: false,
            // 领取日期
            creater_time: "",
            // 备注
            doc: ""
        };
    },
    mounted: function(){
        if (isMobile()) {
            this.flag = true;
        } else {
            this.flag = false;
        }
        this.clientMode = 1;
        // this.getClientList();
        // this.getStaffList();
        this.id = this.$route.query.id;
        if(this.$route.query.client){
            this.client = [Number(this.$route.query.client)];
        }
        if(this.id){
            this.getInvoicDetail();
        }
    },
    watch:{
        client: function(newVal){ 
            if(newVal){
                requestXml("/scrm/Invoice/getOrder","POST",(res) => {
                    this.contractList = res.list;
                },{"ids": this.client})
            }
        },
    },
    methods: {
        chooseClient(){
            this.clientFlag = true;
            this.$refs.sel.blur();
        },
        updateClientFlag(data){
            for(let i=0;i<data.length;i++){
                this.client = data[i].tmjcpb_corp__ids;
                this.client2 = data[i].tmjcpb_corp__name;
            }
            this.clientFlag = false;
        },
        
        // 获取发票详情
        getInvoicDetail() {
            requestXml("/scrm/Invoice/getDetails","GET",(res) => {
                console.log(res)
                // 发票编号
                this.sn = res.sn;
                // 向后台传的客户id
                this.client = res.ids;
                // 前台展示的客户名字
                this.client2 = res.corp_name;
                if(res.oid != 0){
                    this.contract = res.oid;
                }
                this.type = res.type;
                this.sign_time = new moment(getDateTime(res.sign_time));
                this.amount = res.amount;
                this.staff_uid = res.staff_uid;
                this.staff_uid2 = res.staff_name;
                if(res.creater_time != 0){
                    this.creater_time = new moment(getDateTime(res.creater_time));
                }
                this.doc = res.doc;
            },{id: this.id})
        },
        // 获取客户
        // getClientList() {
        //     requestXml("/scrm/Corp/getUnBindCorp","GET",(res) => {
        //         this.clientList = res;
        //     })
        // },
        // 获取员工
        // getStaffList() {
        //     requestXml("/scrm/Staff/getMinList","GET",(res) => {
        //         this.staffList = res;
        //     })
        // },
        // 选择领取人
        showAssign() {
            this.$refs.sel2.blur();
            this.staffFlag = true;
        },
        // 分配完 取消表格选中 清空客户 重新加载列表
        updateStaffFlag(data){
            console.log(data,"dasds")
            if(data != 1){
                this.staff_uid = data.userid;
                this.staff_uid2 = data.name;
            }
            this.staffFlag = false;
        },
        // 添加发票
        submit(){
            if(!this.sn){
                this.$message.warning('请填写发票编号');
                return false;
            }
            if(!this.client){
                this.$message.warning('请选择要添加的客户');
                return false;
            }
            if(!this.type){
                this.$message.warning('请选择发票类型');
                return false;
            }
            if(!this.sign_time){
                this.$message.warning('请选择开票日期');
                return false;
            }
            if(!this.amount){
                this.$message.warning('请填写发票金额');
                return false;
            }
            if(this.creater_time){
                this.creater_time = this.creater_time._d.getTime();
            }else{
                this.creater_time = "";
            }
            // 添加 更新
            var url;
            if(this.id){
                url = "/scrm/Invoice/up"
            }else{
                url = "/scrm/Invoice/add"
            }
            // 手机还是pc
            // var path;
            // if(isMobile()){
            //     path = "/scrm_wap/order/orderList"
            // }else{
            //     path = "/scrm_pc/order"
            // }
            let invioceData = {
                "id": this.id,
                "sn": this.sn,
                "ids": this.client,
                "oid": this.contract,
                "type": this.type,
                "amount": this.amount,
                "sign_time": this.sign_time._d.getTime(),
                "staff_uid": this.staff_uid,
                "creater_time": this.creater_time,
                "doc": this.doc
            }
            requestXml(url,"POST",(res) => {
                console.log(res)
                this.$router.push({
                    path: "/scrm_pc/order/invoiceList",
                });
            },invioceData)
        }
    }
};
</script>
<style scoped>
    .content .content_row{
        align-items: center;
        margin: 3rem 0;
    }
    .content_right{
        width: calc(100% - 120px);
        text-align: left;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
        color: #36A3FF;
        border-color: #36A3FF;
    }
    .content .content_row .info{
        display: inline-block;
        width: 15rem;
        text-align: right;
        padding-right: 1rem;
        line-height: 2.5;
        color: #202020;
        font-weight: normal;
    }
    /* .content .content_row .fileLabel{
        line-height: 1.499;
        font-weight: 400;
        background-image: none;
        border: 1px solid #d9d9d9;
        box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
        cursor: pointer;
        height: 32px;
        line-height: 32px;
        padding: 5px 15px;
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.65);
    } */
    .content .content_row input[type="file"]{
        display: none;
    }
    /* 输入框 下拉框 */
    .content .content_row .ant-input,.content .content_row .ant-select{
        width: 100%;
        /* background-color: #F9F9F9; */
    }
    .content .content_row .ant-select-selection--multiple input{
        width: 0;
    }
    /* 日期 */
    .content .content_row .ant-calendar-picker{
        width: 100% !important;
    }
    .content .content_row .ant-calendar-picker input{
        width: 100%;
    }
    
    .save {
        text-align: left;
    }
    .save .ant-btn{
        width: 100px;
        height: 40px;
        background: #36A3FF;
        border-radius: 4px;
        margin-left: 13rem;
        position: inherit;
    }
    
    @media screen and (min-width: 750px){
        .content .content_row{
            margin: 15px 0;
            font-size: 16px;
        }
        .content .content_row .info{
            width: 90px;
            padding-right: 10px;
        }
        .content .content_row .ant-calendar-picker{
            width: 400px !important;
        }
        .content .content_row .ant-calendar-picker input{
            width: 100% !important;
        }
        /* input ,selsct */
        .content .content_row .ant-input,.content .content_row .ant-input-affix-wrapper,.content .content_row .ant-select{
            width: 400px;
        }
        .save .ant-btn{
            margin-left: 90px;
        }
        
    }
</style>
